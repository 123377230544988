import requestApi from "common/request-api";
export function contactRep(data) {
    return requestApi.post(`api/salesreps/contact`, {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });
}
