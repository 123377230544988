function findParameter(name) {
    const parameter = window.location.search.length > 1 &&
        window.location.search
            .substr(1)
            .split("&")
            .find((x) => x.startsWith(name));
    return parameter || "";
}
function findParameterCaseInsensitive(name) {
    const rx = new RegExp(name, "i");
    const parameter = window.location.search.length > 1 &&
        window.location.search
            .substr(1)
            .split("&")
            .find((x) => rx.test(x));
    return parameter || "";
}
export function removeParameter(name) {
    const modifiedSearch = "?" +
        window.location.search
            .substr(1)
            .split("&")
            .filter((x) => !x.startsWith(name))
            .join("&");
    if (window.location.search && modifiedSearch) {
        window.history.replaceState({}, document.title, window.location.href.replace(window.location.search, modifiedSearch));
    }
}
export function loadParameter(name) {
    const parameter = findParameter(name);
    return (!!parameter && (parameter.indexOf("=") === -1 || parameter.split("=")[1]));
}
export function loadParameterCaseInsensitive(name) {
    const parameter = findParameterCaseInsensitive(name);
    return (!!parameter && (parameter.indexOf("=") === -1 || parameter.split("=")[1]));
}
export function loadAndRemoveParameter(name) {
    const parameter = loadParameter(name);
    if (parameter) {
        removeParameter(name);
    }
    return parameter;
}
