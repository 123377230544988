var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/** @jsx createElement */
import { createElement } from "common/jsx-factory";
import Modal from "common/modal";
import { isAuthorEnvironment } from "common/models/url-base";
import request from "common/request-api";
import EventApi from "common/event-api";
export default class AsyncModal extends Modal {
    constructor(id, cssClass, url) {
        super([]);
        this.id = id;
        this.cssClass = cssClass;
        this.contentUrl = url;
    }
    static get Loaded() {
        return "modal-async--loaded";
    }
    onLoaded() {
        EventApi.trigger(AsyncModal.Loaded, this);
    }
    getId() {
        return this.id;
    }
    getClass() {
        return this.cssClass + " " + super.getClass();
    }
    isClosedOnBackgroundClick() {
        return true;
    }
    isRemovedOnClose() {
        return false;
    }
    loadContent() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield request.get(isAuthorEnvironment()
                ? this.contentUrl + "?wcmmode=disabled"
                : this.contentUrl);
            const text = yield response.text();
            const parser = new DOMParser();
            const doc = parser.parseFromString(text, "text/html");
            this.replaceContent(doc.body.firstElementChild);
            this.onLoaded();
        });
    }
    onCloseClicked(e) {
        e.preventDefault();
        this.close();
    }
    open() {
        if (!this.modalContent) {
            this.loadContent();
        }
        super.open();
    }
    replaceContent(newContent) {
        if (this.modalContent) {
            const parent = this.modalContent.parentElement;
            parent.removeChild(this.modalContent);
            this.modalContent = newContent;
            if (parent.hasChildNodes) {
                parent.insertBefore(this.modalContent, parent.firstElementChild);
            }
            else {
                parent.appendChild(this.modalContent);
            }
        }
    }
    renderAdditionalContent() {
        // Virtual
        return null;
    }
    renderCore() {
        if (!this.modalContent) {
            this.modalContent = createElement("div", { className: "loading-indicator" });
        }
        return (createElement("div", { className: "container" },
            createElement("div", { className: "modal-content-wrapper" },
                createElement("div", { className: "modal-content" },
                    this.modalContent,
                    this.renderAdditionalContent(),
                    createElement("button", { className: "close", type: "button", onClick: this.close.bind(this) },
                        createElement("div", { className: "icon" }))))));
    }
}
const modals = {};
export function loadAsyncModal(id, cssClass, url) {
    return modals[id] || (modals[id] = new AsyncModal(id, cssClass, url));
}
