/** @jsx createElement */
import { createElement } from "common/jsx-factory";
export default function Zip(props) {
    return (createElement("div", { className: "cmp-far__zip cmp-text" },
        createElement("p", { className: "nni-hcp--text--body-3" },
            "Your zip code is ",
            props.zip,
            createElement("a", { className: "nni-hcp--text--body-4", href: "#", onClick: (e) => {
                    e.preventDefault();
                    props.onChange();
                } }, "Change"))));
}
