import requestApi from "common/request-api";
export function getReps(data) {
    const source = data.Source ? `?source=${data.Source}` : "";
    return requestApi
        .get(`api/salesreps/${data.SalesForce}/${data.Zip}${source}`)
        .then((response) => {
        if (response.status !== 200) {
            throw Error("Failed to load Reps");
        }
        return response.json();
    })
        .then((reps) => reps.map((rep) => (Object.assign(Object.assign({}, rep), { salesForce: data.SalesForce }))));
}
export function getSalesForcesReps(data) {
    return Promise.all(data.SalesForces.map((SalesForce) => getReps({ SalesForce, Zip: data.Zip, Source: data.Source }))).then((values) => {
        return values.flat(1);
    });
}
