/** @jsx createElement */
import { createElement } from "common/jsx-factory";
import Modal from "common/modal";
export class ErrorModal extends Modal {
    constructor() {
        super([]);
    }
    getId() {
        return "modal-find-a-rep-error";
    }
    onCancel(e) {
        e.preventDefault();
        this.close();
    }
    renderCore() {
        return (createElement("div", { className: "container" },
            createElement("div", { className: "content-wrapper" },
                createElement("div", { className: "content" },
                    createElement("div", { className: "cmp-text" },
                        createElement("h3", { className: "nni-hcp--text--headline3 nni-hcp--alignment--center nni-hcp--bottom-margin--8x" }, "We\u2019re sorry, something went wrong when trying to determine local representatives in your area. Please try again later.")),
                    createElement("div", { className: "buttons nni-hcp--alignment--center" },
                        createElement("button", { className: "nni-hcp--button nni-hcp--button-style--default nni-hcp--button-size--small" },
                            createElement("div", null,
                                createElement("a", { href: "#", "data-gtm-trigger": "click", "data-gtm-category": "", "data-gtm-action": "", "data-gtm-label": "", "data-gtm-language": "en", onClick: this.onCancel.bind(this) },
                                    createElement("span", { className: "button-text" }, "Close"))))),
                    createElement("a", { href: "#", className: "close", onClick: this.onCancel.bind(this) })))));
    }
}
