/** @jsx createElement */
import { createElement } from "common/jsx-factory";
import account from "common/account-api";
import { loadParameter } from "common/util/querystring";
import Events from "common/event-api";
import { geoZip, getSalesForcesReps } from "./api";
import { userContactData, userZip, removeZipPlusFour, validateZip, } from "./helpers";
import { ContactRepModal, ThankYouModal, ErrorModal } from "./modals";
import { Connect, ZipHeading, ZipHeadingForm, } from "./templates/default/heading";
import { Loading, NoReps, Rep, ZipForm, Error } from "./templates/default/main";
import * as Slim from "./templates/slim";
import AsyncModal from "common/modal/async";
import ScrollToElement from "common/scroll";
import { ease } from "common/util/bezier";
const SELECTOR_CONTACT_MODAL_BUTTON = ".modal-find-a-rep--open";
const SELECTOR_FAR_COMPONENT = ".cmp-far";
const DISPLAY_SLIM = "slim";
const MOCK_REPS = [
    {
        firstName: "Pam",
        lastName: "Beesley",
        email: "pam@example.com",
        repType: "HTM",
        image: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Nicolas_Cage_Deauville_2013.jpg",
        salesForce: "HTM",
    },
    {
        firstName: "Dwight",
        lastName: "Schrute",
        email: "dwight@example.com",
        repType: "FRM",
        image: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Nicolas_Cage_Deauville_2013.jpg",
        salesForce: "FRM",
    },
];
const mockGetReps = (zip) => {
    const len = (parseInt(zip) + 1) % 3;
    return new Promise((resolve) => resolve(MOCK_REPS.slice(0, len)));
};
const MODE_RESULTS = 0;
const MODE_EDIT_ZIP = 1;
const MODE_LOADING = 2;
const MODE_NO_REPS = 3;
const MODE_ERROR = 4;
const MODE_NO_ZIP = 5;
const MODE_THANKS = 6;
const MODE_API_ERROR = 7;
export let Source = "";
function FindARep(component) {
    let Reps = [];
    let Zip;
    let Mode = initializeMode();
    let InitialForm;
    Source = component.dataset["source"];
    const Audience = component.dataset["audience"] || "HCP";
    const SalesForces = salesForces();
    const LegalText = component.dataset["legalText"];
    const ErrorPhone = component.dataset["errorPhone"];
    const DisplayMode = component.dataset["displayMode"];
    const Authoring = !!component.dataset["authoring"];
    const accountZip = () => {
        return account.getUser().then((user) => {
            if (!user) {
                return "";
            }
            return userZip(user);
        });
    };
    const urlZip = () => Promise.resolve(typeof URLSearchParams === "function"
        ? new URLSearchParams(window.location.search).get("zip")
        : "");
    const getZip = () => {
        return Promise.all([accountZip(), geoZip(), urlZip()]).then(([account, geo, url]) => {
            if (validateZip(url)) {
                return url;
            }
            if (validateZip(account)) {
                return account;
            }
            if (validateZip(geo)) {
                return geo;
            }
            Mode = MODE_NO_ZIP;
            update();
            return "";
        });
    };
    const getContactData = () => {
        return account.getUser().then((user) => userContactData(user));
    };
    const renderHeading = () => {
        switch (Mode) {
            case MODE_EDIT_ZIP:
                return ZipHeadingForm({
                    Zip,
                    onSubmit: updateZip,
                });
            case MODE_NO_ZIP:
                return ZipHeading();
            case MODE_RESULTS:
            default:
                return Connect({ Zip, onChange: editZip });
        }
    };
    const renderMain = () => {
        switch (Mode) {
            case MODE_NO_ZIP:
                return ZipForm({
                    Zip,
                    onSubmit: updateZip,
                });
            case MODE_EDIT_ZIP:
            case MODE_RESULTS:
                return Reps.map((rep) => Rep(rep, showContactModal));
            case MODE_LOADING:
                return Loading();
            case MODE_NO_REPS:
                return NoReps(ErrorPhone);
            case MODE_ERROR:
                return Error();
            default:
                return;
        }
    };
    const renderDefault = () => {
        return (createElement("div", { className: "cmp-far__container" },
            createElement("div", { className: "heading" }, renderHeading()),
            createElement("div", { className: ["main", isDouble() && "main--double"]
                    .filter((x) => x)
                    .join(" ") }, renderMain())));
    };
    const renderSlimErrors = () => {
        switch (Mode) {
            case MODE_NO_ZIP:
                return Slim.ZipHeading({
                    zip: Zip,
                    onApply: updateZip,
                });
            case MODE_NO_REPS:
                return Slim.NoReps({ ErrorPhone, Zip, onApply: updateZip });
            case MODE_ERROR:
                return Slim.Error();
        }
    };
    const renderSlim = () => {
        const anyErrors = Mode === MODE_NO_REPS ||
            Mode === MODE_API_ERROR ||
            Mode === MODE_ERROR ||
            Mode === MODE_NO_ZIP;
        const className = `cmp-far__container cmp-far__slim ${anyErrors ? "full-width-content" : ""}`;
        if (anyErrors) {
            return createElement("div", { className: className }, renderSlimErrors());
        }
        if (Mode === MODE_LOADING) {
            return createElement("div", { className: className }, Loading());
        }
        return (createElement("div", { className: className },
            (Mode === MODE_RESULTS || Mode === MODE_EDIT_ZIP) &&
                Slim.Heading({
                    // TODO test code
                    // rep: MOCK_REPS[0],
                    rep: Reps[0],
                }),
            (Mode === MODE_RESULTS || Mode === MODE_EDIT_ZIP) &&
                Slim.RepImage({
                    // TODO test code
                    // rep: MOCK_REPS[0],
                    rep: Reps[0],
                }),
            (Mode === MODE_RESULTS || Mode === MODE_EDIT_ZIP) &&
                Slim.ContactButton({
                    showModal: showContactModal,
                    // TODO test code
                    // rep: MOCK_REPS[0],
                    rep: Reps[0],
                }),
            Mode === MODE_RESULTS &&
                Slim.Zip({
                    zip: Zip,
                    onChange: editZip,
                }),
            Mode === MODE_EDIT_ZIP &&
                Slim.ZipForm({
                    zip: Zip,
                    onApply: updateZip,
                })));
    };
    function salesForces() {
        const all = component.dataset["salesForce"].split(",") || ["HTM"];
        const query = loadParameter("salesForce");
        if (query) {
            const filter = query.split(",");
            return all.filter((salesForce) => filter.includes(salesForce));
        }
        return all;
    }
    const isDouble = () => {
        if (Reps.length === 0) {
            return SalesForces.length > 1;
        }
        return Reps.length > 1;
    };
    const editZip = () => {
        Mode = MODE_EDIT_ZIP;
        update();
    };
    const updateZip = (zip) => {
        if (validateZip(zip)) {
            Zip = removeZipPlusFour(zip);
            Mode = MODE_LOADING;
            getSalesForcesReps({ Zip, SalesForces, Source: Source })
                .then(updateReps)
                .catch((error) => {
                Mode = MODE_ERROR;
                // TODO test code
                // updateReps(MOCK_REPS);
            })
                .finally(() => {
                update();
            });
        }
    };
    const updateReps = (reps) => {
        Reps = reps;
        Mode = MODE_RESULTS;
        if (Reps.length === 0) {
            Mode = MODE_NO_REPS;
        }
    };
    const handleContact = (response, Rep) => {
        if (response.status === 201) {
            window === null || window === void 0 ? void 0 : window.dataLayer.push({
                event: "nml.event",
                eventCategory: "Find a Rep",
                eventAction: "Requested Rep Contact",
                eventLabel: Rep.salesForce,
                eventIntendedAudience: "HCP",
                eventLanguage: "en",
            });
            showThankYouModal(Rep);
        }
        else {
            showErrorModal();
        }
    };
    const showContactModal = (Rep) => {
        const modal = new ContactRepModal({
            Rep,
            LegalText,
            Source,
            Audience,
            Zip,
            callback: handleContact,
            InitialForm,
        });
        window === null || window === void 0 ? void 0 : window.dataLayer.push({
            event: "nml.event",
            eventCategory: "Find a Rep",
            eventAction: "Opened Rep Contact Form",
            eventLabel: Rep.salesForce,
            eventIntendedAudience: "HCP",
            eventLanguage: "en",
        });
        modal.open();
    };
    const showThankYouModal = (Rep) => {
        const modal = new ThankYouModal({ Rep });
        modal.open();
    };
    const showErrorModal = () => {
        const modal = new ErrorModal();
        modal.open();
    };
    const render = () => {
        switch (DisplayMode) {
            case DISPLAY_SLIM:
                return renderSlim();
            default:
                return renderDefault();
        }
    };
    //initialize
    let app = render();
    component.appendChild(app);
    const update = () => {
        component.removeChild(app);
        app = render();
        component.appendChild(app);
    };
    const contactModalButtons = [];
    const initializeContactModalButtons = () => {
        [].slice
            .call(document.querySelectorAll(SELECTOR_CONTACT_MODAL_BUTTON))
            .forEach((button) => {
            if (!contactModalButtons.includes(button)) {
                contactModalButtons.push(button);
                button.addEventListener("click", (e) => {
                    e.preventDefault();
                    if (Reps.length > 0) {
                        showContactModal(Reps[0]);
                    }
                    else {
                        const tween = new ScrollToElement(component, ease, 500);
                        tween.go();
                    }
                });
            }
        });
    };
    initializeContactModalButtons();
    Events.on(AsyncModal.Loaded, initializeContactModalButtons.bind(this));
    if (Authoring) {
        Mode = MODE_NO_REPS;
        update();
    }
    else {
        if (Mode === MODE_LOADING) {
            // TODO test code
            // InitialForm = {
            //   FirstName: "Cameron",
            //   LastName: "Cozza",
            //   Email: "Cameroncozza@fgmail.com",
            //   Phone: "8054415408",
            // };
            // updateZip("98229");
            getZip().then((zip) => {
                updateZip(zip);
            });
            getContactData().then((userData) => {
                InitialForm = userData;
            });
        }
        else {
            switch (Mode) {
                case MODE_THANKS:
                    showThankYouModal(MOCK_REPS[0]);
                    break;
                case MODE_API_ERROR:
                    showErrorModal();
                    break;
            }
        }
    }
}
function initializeMode() {
    switch (window.location.hash) {
        case "#far-no-zip":
            return MODE_NO_ZIP;
        case "#far-error":
            return MODE_ERROR;
        case "#far-thanks":
            return MODE_THANKS;
        case "#far-api-error":
            return MODE_API_ERROR;
        case "#far-no-reps":
            return MODE_NO_REPS;
    }
    return MODE_LOADING;
}
function initializeFindARep() {
    const components = document.querySelectorAll(SELECTOR_FAR_COMPONENT);
    components.forEach((component) => {
        FindARep(component);
    });
}
document.addEventListener("DOMContentLoaded", initializeFindARep);
