/** @jsx createElement */
import { createElement } from "common/jsx-factory";
import ZipForm from "./zipForm";
export default function NoReps(props) {
    const { ErrorPhone, Zip, onApply } = props;
    return (createElement("div", { className: "no-reps" },
        createElement("div", { className: "cmp-text nni-hcp--alignment--center" },
            createElement("h3", { className: "nni-hcp--text--headline3" }, "We\u2019re sorry."),
            createElement("p", { className: "nni-hcp--text--body-2" },
                "There do not appear to be any representatives in your area.",
                " ",
                ErrorPhone && (createElement("span", null,
                    "Please call ",
                    createElement("strong", null, ErrorPhone),
                    " for further assistance.")))),
        ZipForm({
            zip: Zip,
            onApply,
        })));
}
