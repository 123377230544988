/** @jsx createElement */
import { createElement } from "common/jsx-factory";
import Modal from "common/modal";
import { titleCase } from "../helpers";
export class ThankYouModal extends Modal {
    constructor(props) {
        super([]);
        this.Rep = props.Rep;
    }
    getId() {
        return "modal-find-a-rep-thank-you";
    }
    onCancel(e) {
        e.preventDefault();
        this.close();
    }
    renderCore() {
        return (createElement("div", { className: "container" },
            createElement("div", { className: "content-wrapper" },
                createElement("div", { className: "content" },
                    createElement("div", { className: "cmp-text" },
                        createElement("h3", { className: "nni-hcp--text--headline3 nni-hcp--alignment--center nni-hcp--bottom-margin--2x" }, "Thank you!"),
                        createElement("p", { className: "nni-hcp--text-body-xl nni-hcp--alignment--center" },
                            "Your message has been received.",
                            " ",
                            createElement("span", { className: "rep__name" }, titleCase(this.Rep.firstName)),
                            " ",
                            "will contact you in the next few days.")),
                    createElement("a", { href: "#", className: "close", onClick: this.onCancel.bind(this) })))));
    }
}
