var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Animation from "common/animation";
import { ease } from "common/util/bezier";
import { getScrollOffset } from "common/util/misc";
export default class ScrollToElement {
    constructor(targetElement, bezier = ease, duration = 500, scrollElement = document.documentElement) {
        this.element = targetElement;
        const stickySubNav = document.querySelector(".subnav-menu__header");
        const headerOffset = hasPositionStickySupport() && scrollElement.contains(stickySubNav)
            ? stickySubNav.offsetHeight
            : 0;
        this.animation = new Animation(scrollElement, "scrollTop", getScrollOffset(this.element, scrollElement) - headerOffset, duration, bezier);
    }
    go() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.animation.play().then(this.focusElement.bind(this));
        });
    }
    focusElement() {
        this.element.focus();
        if (document.activeElement === this.element) {
            return;
        }
        else {
            this.element.tabIndex = -1;
            this.element.focus();
        }
    }
}
const hasPositionStickySupport = () => {
    const testNode = document.createElement("div");
    return ["", "-webkit-", "-moz-", "-ms-"].some(function (prefix) {
        testNode.style.position = prefix + "sticky";
        return testNode.style.position != "";
    });
};
