/** @jsx createElement */
import { createElement } from "common/jsx-factory";
export function ZipForm({ Zip, onSubmit }) {
    let value = Zip;
    const onChange = (e) => {
        value = e.target.value;
    };
    return (createElement("form", { className: "zip-form cmp-text", onSubmit: (e) => {
            e.preventDefault();
            onSubmit(value);
        } },
        createElement("p", { className: "nni-hcp--text--headline2" }, "Enter your zip code"),
        createElement("input", { type: "text", name: "Zip", value: Zip, size: 9, onKeyUp: onChange }),
        createElement("button", { className: "nni-hcp--button nni-hcp--button-style--default nni-hcp--button-size--small" },
            createElement("div", null,
                createElement("a", null,
                    createElement("span", { className: "button-text" }, "Find your representative"))))));
}
