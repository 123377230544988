function primaryAddress(user) {
    if (user.addresses) {
        const firstAddress = user.addresses.find(() => true);
        const primaryAddress = user.addresses.find((a) => a.primary);
        return primaryAddress || firstAddress;
    }
}
export function userZip(user) {
    const address = primaryAddress(user);
    if (address) {
        return address.zip;
    }
    return "";
}
export function userContactData(user) {
    const data = {
        FirstName: "",
        LastName: "",
        Phone: "",
        Email: "",
    };
    if (user) {
        data.FirstName = user.firstName;
        data.LastName = user.lastName;
        data.Email = user.email;
        const address = primaryAddress(user);
        if (address) {
            data.Phone = address.phone;
        }
    }
    return data;
}
