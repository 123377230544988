/** @jsx createElement */
import { createElement } from "common/jsx-factory";
import { titleCase } from "../../../helpers";
import { Source } from "../../../find-a-rep";
const SALES_FORCE_TITLE_MAP = {
    HTM: "Hemophilia Therapy Manager",
    HIAM: "Hemophilia Institutional Account Manager",
    GDTM: "Growth Disorder Territory Manager",
    FRM: "Field Reimbursement Manager",
    RRCAM: "Rare Renal Therapy Specialist",
};
const SALES_FORCE_HELPS_WITH = {
    HTM: [
        "Clinical product information",
        "Trial prescriptions",
        "Hemophilia education for patients",
        "Resources for starting treatment",
        "General questions & inquiries",
    ],
    HIAM: [
        "Clinical product information",
        "Trial prescriptions",
        "Hemophilia education for patients",
        "Resources for starting treatment",
        "General questions & inquiries",
    ],
    GDTM: [
        "Clinical product information",
        "Formulary status",
        "Co-pay programs",
        "General questions & inquiries",
    ],
    FRM: [
        "Patient insurance coverage",
        "SMN questions/prior authorizations",
        "Specialty distributors",
        "Appeals support information",
        "Case status updates",
    ],
    RRCAM: [
        "Product information",
        "Primary Hyperoxaluria Type 1 (PH1) information",
        "Patient screening inquiries",
        "Resources for starting treatment",
        "Patient support including co-pay program",
        "Formulary status",
        "General questions & inquiries",
    ],
};
const SALES_FORCE_HELPS_WITH_SOURCE = {
    ALHEMOPROFINDAREP: [
        {
            HTM: [
                "Clinical product information",
                "Hemophilia education for patients",
                "Resources for starting treatment",
                "General questions & inquiries",
            ],
            HIAM: [
                "Clinical product information",
                "Hemophilia education for patients",
                "Resources for starting treatment",
                "General questions & inquiries",
            ],
        },
    ],
};
export function Rep(rep, showModal) {
    function title(salesForce) {
        if (!salesForce || !SALES_FORCE_TITLE_MAP[salesForce]) {
            return "";
        }
        return SALES_FORCE_TITLE_MAP[salesForce];
    }
    function helpsWith(salesForce) {
        let FARSource;
        if (Source &&
            salesForce &&
            Source == "alhemopro-findarep" &&
            (salesForce == "HTM" || salesForce == "HIAM")) {
            FARSource = "ALHEMOPROFINDAREP";
        }
        if (!salesForce || !SALES_FORCE_HELPS_WITH[salesForce]) {
            return [];
        }
        return FARSource
            ? SALES_FORCE_HELPS_WITH_SOURCE[FARSource][0][salesForce]
            : SALES_FORCE_HELPS_WITH[salesForce];
    }
    if (!rep) {
        return;
    }
    const onClick = (e) => {
        e.preventDefault();
        showModal(rep);
    };
    return (createElement("div", { className: "rep" },
        createElement("div", { className: "cmp-text" },
            createElement("p", { className: "rep__name nni-hcp--text--headline3 nni-hcp--alignment--left nni-hcp--bottom-margin--1x" },
                titleCase(rep.firstName),
                " ",
                titleCase(rep.lastName)),
            createElement("p", { className: "rep__salesForce nni-hcp--text--body-1 nni-hcp--alignment--left nni-hcp--top-margin--1x nni-hcp--bottom-margin--1x" }, title(rep.salesForce))),
        createElement("div", { className: "nni-hcp--button nni-hcp--button-style--default nni-hcp--button-size--small nni-hcp--alignment--left" },
            createElement("div", null,
                createElement("a", { href: "#", onClick: onClick },
                    createElement("span", { className: "button-text" }, "Contact")))),
        createElement("hr", { className: "nni-hcp--top-margin--2x nni-hcp--bottom-margin--2x" }),
        createElement("div", { className: "cmp-text" },
            createElement("strong", { className: "nni-hcp--text--body-1" }, "Can help you with:"),
            createElement("ul", { className: "rep__list nni-hcp--text--body-2" }, helpsWith(rep.salesForce).map((topic) => {
                /* this is not react */
                /* eslint-disable react/jsx-key */
                return createElement("li", { className: "rep__item" }, topic);
            })))));
}
