export function titleCase(value) {
    if (!value) {
        return "";
    }
    return value[0].toUpperCase() + value.slice(1).toLowerCase();
}
const REGEX_NOT_DIGITS = /[^0-9]/g;
const REGEX_NOT_ZIP_PLUS_FOUR = /[^0-9-]/g;
export function cleanPhone(phone) {
    if (!phone) {
        return "";
    }
    return phone.replace(REGEX_NOT_DIGITS, "");
}
export function cleanZip(zip) {
    if (!zip) {
        return "";
    }
    return zip.replace(REGEX_NOT_ZIP_PLUS_FOUR, "");
}
const REGEX_ZIP = /^[0-9]{5}/;
export function removeZipPlusFour(zip) {
    if (!zip) {
        return "";
    }
    const match = cleanZip(zip).match(REGEX_ZIP);
    if (match) {
        return match[0];
    }
    return "";
}
