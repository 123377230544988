export function createElement(tagName, attributes, ...children) {
    const element = document.createElement(tagName);
    if (attributes) {
        for (const entry of Object.entries(attributes)) {
            setAttribute(element, entry[0], entry[1]);
        }
    }
    for (const child of children) {
        appendChild(element, child);
    }
    return element;
}
// eslint-disable-next-line @typescript-eslint/no-namespace
(function (createElement) {
})(createElement || (createElement = {}));
function setAttribute(element, attribute, attributeValue) {
    if (attribute === "dangerouslySetInnerHTML") {
        element.innerHTML = attributeValue;
    }
    else if (typeof attributeValue === "string") {
        setAttributeToString(element, attribute === "className" ? "class" : attribute, // JSX does not allow class as a valid name
        attributeValue);
    }
    else if (typeof attributeValue === "number") {
        setAttributeToString(element, attribute, attributeValue.toString());
    }
    else if (typeof attributeValue === "boolean") {
        setAttributeToString(element, attribute, "");
    }
    else if (typeof attributeValue === "function" &&
        attribute.substr(0, 2) === "on") {
        addEventListener(element, attribute, attributeValue);
    }
    else if (typeof attributeValue === "function" && attribute === "ref") {
        callRefCallback(element, attributeValue);
    }
    else if (typeof attributeValue === "object" && attribute === "ref") {
        assignRef(element, attributeValue);
    }
    else if (typeof attributeValue === "object" && attribute === "style") {
        addStyles(element, attributeValue);
    }
}
function setAttributeToString(element, attribute, attributeValue) {
    element.setAttribute(attribute, attributeValue);
}
function addEventListener(element, event, eventListener) {
    element.addEventListener(event.substr(2).toLowerCase(), eventListener);
}
function callRefCallback(element, ref) {
    ref(element);
}
function assignRef(element, ref) {
    ref.current = element;
}
function addStyles(element, styles) {
    for (const entry of Object.entries(styles)) {
        element.style.setProperty(entry[0], typeof entry[1] === "string" ? entry[1] : entry[1].toString());
    }
}
function appendChild(parent, child) {
    if (typeof child === "string") {
        parent.appendChild(document.createTextNode(child));
    }
    else if (child instanceof HTMLElement) {
        parent.appendChild(child);
    }
    else if (Array.isArray(child)) {
        child.forEach((x) => appendChild(parent, x));
    }
}
export function createRef() {
    return { current: null };
}
