/** @jsx createElement */
import { createElement } from "common/jsx-factory";
export default function ContactButton(props) {
    if (!props.rep) {
        return;
    }
    const onClick = (e) => {
        e.preventDefault();
        props.showModal(props.rep);
    };
    const settings = window.NovoMedLink && window.NovoMedLink.Settings;
    const ta = settings && settings.therapeuticArea && settings.therapeuticArea.name;
    const brand = settings && settings.brand && settings.brand.analyticsName;
    return (createElement("div", { className: "cmp-far__contact-button nni-hcp--button nni-hcp--button-style--default nni-hcp--button-size--small nni-hcp--alignment--left" },
        createElement("div", null,
            createElement("a", { href: "#", onClick: onClick, "data-gtm-trigger": "click", "data-gtm-intended-audience": "HCP", "data-gtm-language": "en", "data-gtm-therapeutic-area": ta || "", "data-gtm-brand": brand || "", "data-gtm-category": "Calls To Action", "data-gtm-action": "Followed", "data-gtm-label": "Rep Contact Form" },
                createElement("span", { className: "button-text" }, "Contact now")))));
}
